import React from "react"
import {injectIntl} from "gatsby-plugin-react-intl"
import {Container} from "react-bootstrap";
import Seo from "../../../components/seo"
import HeroSubpage from "../../../components/heroes/hero-subpage"
import ImageBlock from "../../../components/misc/image-block";
import ParagraphBlock from "../../../components/misc/paragraph-block";
import TextBlock from "../../../components/misc/text-block";
import LayoutSecondary from "../../../components/layout-secondary";
import SimpleBlock from "../../../components/misc/simple-block";
import Quote from "../../../components/misc/quote";
import CaseData from "../../../content/case-studies/check/index.json";
import SimpleCTA from "../../../components/ctas/simple-cta";

const CaseStudiesCheckPage = ({ intl, location }) => (
    <LayoutSecondary>
        <Seo
            lang={intl.locale}
            title={CaseData[intl.locale].title}
            description={CaseData[intl.locale].text}
            pathname={location.pathname}
        />
        <HeroSubpage
            theme={`secondary`}
            content={CaseData[intl.locale]}
        />
        <div className={`case-study`}>
            {CaseData[intl.locale].content.map((c, i) => {
                if (c.type === "image-block") {
                    return (
                        <Container key={i}>
                            <ImageBlock content={c}/>
                        </Container>
                    )
                } else if (c.type === "text-block") {
                    return (
                        <Container key={i}>
                            <TextBlock content={c} />
                        </Container>
                    )
                } else if (c.type === "simple-block") {
                    return (
                        <Container key={i}>
                            <SimpleBlock content={c} />
                        </Container>
                    )
                } else if (c.type === "quote") {
                    return (
                        <div key={i} className={`bg-light`}>
                            <Container>
                                <Quote content={c} />
                            </Container>
                        </div>
                    )
                } else if (c.type === "simple-cta") {
                    return (
                        <Container key={i}>
                            <div style={{marginBottom: `40px`}}>
                                <SimpleCTA theme={`secondary`} content={c} />
                            </div>
                        </Container>
                    )
                } else {
                    return (
                        <Container key={i}>
                            <ParagraphBlock content={c} />
                        </Container>
                    )
                }
            })}
        </div>
    </LayoutSecondary>
)

export default injectIntl(CaseStudiesCheckPage)
